import { create } from 'zustand'
import { produce } from 'immer'

export default create((set, get) => ({
  collection: [],
  filters: {archive: false},
  sortKey: 'sku',
  sortType: 'string',
  sortDirection: 'asc',
  updates: (products) => {
    const newProducts = [...get().collection]
    products.forEach(product => {
      const index = newProducts.findIndex(p => p.id == product.id)
      newProducts.splice(index, 1, product)
    })
    set({ collection: newProducts })
  },
  updateFilter: (key, value) => {
    set(produce(state => {
      state.filters[key] = value
    }))
  },
  setSort: (key, type = 'string') => {
    set(produce(state => {
      if (state.sortKey == key) {
        state.sortDirection = state.sortDirection == 'asc' ? 'desc' : 'asc'
      } else {
        state.sortDirection = 'asc'
      }
      state.sortKey = key
      state.sortType = type
    }))
  },
  fetch: async () => {
    const response = await fetch('/api/products')
    const data = await response.json()
    set({ collection: data })
  },
  filtered: () => {
    const collection = get().collection
    if (!collection || collection.length == 0) {
      return []
    }
    const filters = get().filters
    const filtered = collection.filter(product => {
      return Object.keys(filters).every(key => {
        if (typeof filters[key] == 'boolean') {
          return product[key] === filters[key]
        }
        if (filters[key] == '') {
          return true
        }
        if (filters[key].length > 1) {
          const operator = filters[key].slice(0, 1)
          const value = filters[key].slice(1)
          if (operator == '>') return parseInt(product[key]) > parseInt(value)
          if (operator == '<') return parseInt(product[key]) < parseInt(value)
          if (operator == '=') return (product[key] ?? '').trim().toLowerCase() == value.trim().toLowerCase()
        }
        const matches = filters[key].toLowerCase().trim()
          .split(' ')
          .map(word => {
            const regex = new RegExp(`\\b${word.toLowerCase()}`, 'i')
            return regex.test(product[key]) ? 1 : 0
          })
        return matches.indexOf(0) === -1
      })
    })
    const sortKey = get().sortKey
    const sortDirection = get().sortDirection
    const sortType = get().sortType
    return filtered.sort((a, b) => {
      if (sortType == 'string') {
        const aVal = (a[sortKey] ?? '').toString()
        const bVal = (b[sortKey] ?? '').toString()
        if (sortDirection == 'asc') 
          return aVal.localeCompare(bVal.toString())
        return bVal.localeCompare(aVal.toString())
      }
      const aVal = parseInt(a[sortKey])
      const bVal = parseInt(b[sortKey])
      if (sortDirection == 'asc') 
        return aVal < bVal ? -1 : 1
      return aVal > bVal ? -1 : 1
    })
  },
  update: async (product) => {
    const response = await fetch(`/api/products/${product.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({product: product})
    })
    const data = await response.json()
    set((state) => {
      const newProducts = [...state.collection]
      const index = newProducts.findIndex(p => {
        return p.id == product.id
      })
      newProducts[index] = product
      return {collection: newProducts}
    })
  },
  archive: async (product) => {
    const response = await fetch(`/api/products/${product.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    const data = await response.json()
    set((state) => {
      const newProducts = [...state.collection]
      const index = newProducts.findIndex(p => {
        return p.id == product.id
      })
      newProducts.splice(index, 1, data)
      return {collection: newProducts}
    })
  }
}))
