import React from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge'

export default ({type = "primary", block = false, children, ...props}) => {
  const typeClasses = {
    primary: 'bg-blue-500 hover:bg-blue-700',
    success: 'bg-green-500 hover:bg-green-700',
    danger: 'bg-red-500 hover:bg-red-700',
  }

  const classes = twMerge(
    'transition text-white font-bold py-2 px-4 rounded', 
    block && 'block w-full text-left', 
    typeClasses[type], 
    props.disabled && 'bg-gray-300 hover:bg-gray-300 cursor-not-allowed'
  )

  return <>
    {props.to
      ? <Link className={classes} {...props}>
        {children}
      </Link>
      : <button className={classes} {...props}>
        {children}
      </button>
    }
  </>
}
