import React, { useState, useEffect } from 'react'
import Select2 from 'react-select'
import regionStore from '../stores/region'

export const categories = [
  { value: '', label: '- select -', selected: true },
  { value: 'Field Team Gear Request', label: 'Field Team Gear Request', selected: true },
  { value: 'Gear Store Credit', label: 'Gear Store Credit', selected: true },
  { value: 'Gear Store Order', label: 'Gear Store Order', selected: true },
  { value: 'Hug', label: 'Hug', selected: true },
  { value: 'Inventory Adjustment', label: 'Inventory Adjustment', selected: true },
  { value: 'Marketing', label: 'Marketing', selected: true },
  { value: 'New Hires', label: 'New Hires', selected: true },
  { value: 'Ops Pack', label: 'Ops Pack', selected: true },
  { value: 'One-Off', label: 'One-Off', selected: true },
  { value: 'Pre-Trip Package', label: 'Pre-Trip Package', selected: true },
  { value: 'ZLIND', label: 'ZLIND', selected: true },
]

export default function ({onChange, value}) {

  const [defaultCategory, setDefaultCategory] = useState(null);

  useEffect(() => {
    setDefaultCategory(categories.find((r) => r.value == value))
  }, [value])

  return (
    <div className="mb-4">
      <label className="block font-bold">Category</label>
      <Select2
        options={categories}
        onChange={onChange}
        menuPlacement="top"
        value={defaultCategory}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        isClearable
      />
    </div>
  )
}
