import React, { useState, useEffect } from 'react'
import Select2 from 'react-select'
import destinationStore from '../stores/destination'

export default function ({onChange, value}) {

  const [defaultDestinations, setDefaultDestinations] = useState(null)
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const [destinations, fetchDestinations] = destinationStore(state => [state.collection, state.fetch])

  useEffect(() => {
    fetchDestinations()
  }, [fetchDestinations])

  useEffect(() => {
    if (!value) return
    if (!destinations) return
    setDefaultDestinations(value.map((destination_id) => destinations.find((d) => d.value == destination_id)))
  }, [destinations, value])

  function onInputChange(inputValue, {action, prevInputValue}) {
    if (action === 'input-change') return inputValue
    if (action === 'input-blur') setMenuIsOpen(false)
    if (action === 'menu-close') return
    return prevInputValue;
  }

  return (
    <div className="mb-4">
      <label className="block font-bold">Destinations</label>
      <Select2
        isClearable
        isSearchable
        options={destinations}
        onChange={onChange}
        menuPlacement="top"
        value={defaultDestinations}
        closeMenuOnSelect={false}
        onInputChange={onInputChange}
        onFocus={e => setMenuIsOpen(true)}
        isMulti
        menuIsOpen={menuIsOpen}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
    </div>
  )
}
