import { create } from 'zustand'
import { v4 as uuid } from 'uuid'

export default create((set, get) => ({
  collection: [],
  add: (notification) => {
    notification.id = uuid()
    set({ collection: [notification, ...get().collection] })
    setTimeout( () => {
      const newCollection = get().collection
      newCollection.pop()
      set({ collection: newCollection })
    }, 3000)
  }
}))