import React, {useState, useEffect} from 'react';
import loading from '../static/loading.webp';

const Loading = () => {

  const [text, setText] = useState('Loading.')

  useEffect(() => {
    const intervalId = setInterval(() => {
      setText(text => text.length < 10 ? text + '.' : 'Loading')
    }, 750)
    return () => clearInterval(intervalId)
  }, [])
  return <div className="py-16 flex flex-col items-center gap-16">
    <img src={loading} />
    <p className="font-bold text-2xl">{text}</p>
  </div>
}

export default Loading;
