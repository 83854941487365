import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

const toggleBodyScroll = (disable) => {
  const body = document.body;
  if (disable) {
    body.classList.add('overflow-hidden');
    body.classList.add('pr-4');
  } else {
    body.classList.remove('overflow-hidden');
    body.classList.remove('pr-4');
  }
};

const Modal = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    toggleBodyScroll(isOpen);
    return () => {
      toggleBodyScroll(false);
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4"
      onClick={onClose}
    >
      <div
        className="bg-white rounded w-4/5 max-w-2xl max-h-full flex flex-col"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

Modal.Header = ({ children, onClose }) => (
  <div className="flex justify-between items-center px-4 py-2 border-b">
    <h2 className="text-xl font-semibold m-0">{children}</h2>
    <button
      onClick={onClose}
      className="text-gray-600 hover:text-gray-800"
    >
      <i className="fas fa-times" />
      <FontAwesomeIcon icon={icon({name: 'times'})} />
    </button>
  </div>
);

Modal.Body = ({ children }) => (
  <div className="px-4 pt-4 shrink overflow-auto">
    {children}
  </div>
);

Modal.Footer = ({ children }) => (
  <div className="px-4 py-2 border-t">
    {children}
  </div>
);

export default Modal;
