import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import Table from '../components/Table'

const SortableHeader = ({children, sortKey, sortType, setSort, currentSortKey, sortDirection}) => {
  const active = sortKey === currentSortKey
  const sortIcon = sortDirection === 'asc' || !active ? icon({name: 'sort-down'}) : icon({name: 'sort-up'})
  return (
    <Table.Th>
      <div className={`cursor-pointer ${active ? 'text-sky-800' : ''}`} onClick={e => setSort(sortKey, sortType)}>
        {children}
        <FontAwesomeIcon icon={sortIcon} className="ml-2" />
      </div>
    </Table.Th>
  )
}

export default SortableHeader
