import { create } from 'zustand'
import { produce } from 'immer'
import productStore from './product'
import { persist } from 'zustand/middleware'

export default create(
  persist(
    (set, get) => ({
      collection: [],
      showAll: false,
      filters: {},
      sortKey: 'id',
      sortType: 'integer',
      sortDirection: 'desc',
      setCollection: (collection) => {
        set({ collection })
      },
      updateFilter: (key, value) => {
        set(produce(state => {
          state.filters[key] = value
        }))
      },
      setShowAll: (showAll) => {
        set({ showAll })
      },
      fetch: async () => {
        const response = await fetch('/api/inventory_transactions/purchase_orders.json')
        const data = await response.json()
        set({ collection: data.collection })
      },
      setSort: (key, type = 'string') => {
        set(produce(state => {
          if (state.sortKey == key) {
            state.sortDirection = state.sortDirection == 'asc' ? 'desc' : 'asc'
          } else {
            state.sortDirection = 'asc'
          }
          state.sortKey = key
          state.sortType = type
        }))
      },
      filtered: () => {
        const collection = get().collection
        if (!collection || collection.length == 0) {
          return []
        }
        const filters = get().filters
        const showAll = get().showAll
        const filtered = collection.filter(po => {
          if (!(showAll || !po.closed)) return false
          if (filters.id && !po.id.toString().startsWith(filters.id)) return false
          if (filters.po_number && !po.po_number.startsWith(filters.po_number)) return false
          if (filters.product) {
            if (po.products.every(product => !product.title.toLowerCase().includes(filters.product.toLowerCase()))) {
              return false
            }
          }
          if (filters.expected_on) {
            const operator = filters.expected_on.slice(0, 1)
            let value
            if (operator == '>' || operator == '<') {
              value = Date.parse(filters.expected_on.slice(1)).valueOf()
            } else {
              value = Date.parse(filters.expected_on).valueOf()
            }
            const poDate = Date.parse(po.expected_on).valueOf()
            if (operator == '>' && poDate <= value) return false
            else if (operator == '<' && poDate >= value) return false
            else if (poDate != value) return false
          }
          if (filters.notes && !po.notes.toLowerCase().includes(filters.notes.toLowerCase())) return false
          return true
        })
        const sortKey = get().sortKey
        const sortDirection = get().sortDirection
        const sortType = get().sortType
        return filtered.sort((a, b) => {
          if (sortType == 'string') {
            const aVal = (a[sortKey] ?? '').toString()
            const bVal = (b[sortKey] ?? '').toString()
            if (sortDirection == 'asc') 
              return aVal.localeCompare(bVal.toString())
            return bVal.localeCompare(aVal.toString())
          }
          const aVal = parseInt(a[sortKey])
          const bVal = parseInt(b[sortKey])
          if (sortDirection == 'asc') 
            return aVal < bVal ? -1 : 1
          return aVal > bVal ? -1 : 1
        })
      },
    }), {
      name: 'purchase-order-storage',
    }
  )
)
