import React, { useState } from 'react';
import transactionStore from '../stores/transaction';
import {v4 as uuid} from 'uuid'

export default () => {
  const [direction, setDirection, purchase_order_id] = transactionStore(state => [state.direction, state.setDirection, state.purchase_order_id])
  const [id] = useState(uuid())
  if (purchase_order_id) return
  return (
    <div className="flex justify-center gap-4">
      <label className="flex items-center">
        <input
          type="radio"
          name={`direction-${id}`}
          value="1"
          checked={direction == '1'}
          onChange={e => setDirection(e.target.value)}
        />
        <span className="ml-2">Receive</span>
      </label>
      <label className="flex items-center">
        <input
          type="radio"
          name={`direction-${id}`}
          value="-1"
          checked={direction == '-1'}
          onChange={e => setDirection(e.target.value)}
        />
        <span className="ml-2">Ship</span>
      </label>
      <label className="flex items-center">
        <input
          type="radio"
          name={`direction-${id}`}
          value="0"
          checked={direction == '0'}
          onChange={e => setDirection(e.target.value)}
        />
        <span className="ml-2">Purchase</span>
      </label>
    </div>
  )
}
