import { create } from 'zustand'

export default create((set, get) => ({
  collection: [],
  fetching: false,
  fetch: async () => {
    if (get().fetching) return
    set({ fetching: true })
    const response = await fetch('/destinations.json')
    const data = await response.json()
    set({ collection: data })
  },
}))
