import React, { useState } from 'react';
import { Text } from './Fields'
import productStore from '../stores/product'
import transactionStore from '../stores/transaction'
import DirectionSelector from '../components/DirectionSelector'

export default () => {
  const [magentoId, setMagentoId] = useState('');
  const [products] = productStore((state) => [state.collection]);
  const [addToTransaction] = transactionStore((state) => [state.add]);
  const [error, setError] = useState([]);
  function keyUp(e) {
    if (e.key === 'Enter') {
      const product = products.find(product => product.magento_id === magentoId)
      if (product) {
        addToTransaction(product)
        setMagentoId('')
        setError([])
      } else {
        setError(['Product not found'])
      }
    }
  }
  return (
    <div className="flex gap-2">
      <DirectionSelector />
      <Text
        value={magentoId}
        onChange={e => setMagentoId(e.target.value)}
        placeholder="Quick Add"
        onKeyUp={keyUp}
        errors={error}
        />
    </div>
  )
}
