import React, { useState, useEffect, useRef, useContext, createContext, useMemo } from 'react'
import Table from './components/Table'
import { useParams } from 'react-router-dom'
import numeral from 'numeral'
import { categories as defaultCategories } from './components/CategorySelect'
import Loading from './components/Loading'
import Button from './components/Button'

const ReportContext = createContext()

const TransactionRow = ({ transaction }) => {

  return (
    <Table.Row className={transaction.direction > 0 ? 'bg-green-50' : 'bg-red-50'}>
      <Table.Td>{transaction.id}</Table.Td>
      <Table.Td>{transaction.created_at}</Table.Td>
      <Table.Td>{transaction.direction_label}</Table.Td>
      <Table.Td>
        <ul className="list-disc pl-6">
          {transaction.items_attributes && transaction.items_attributes.map((item) =>
            <li key={item.id}>{item.product.magento_id} - {item.quantity} - {item.title}</li>
          )}
        </ul>
      </Table.Td>
      <Table.Td className="text-right">{transaction.product_count}</Table.Td>
      <Table.Td className="text-right">{transaction.total_count}</Table.Td>
    </Table.Row>
  )
}

const CsvTable = ({ title, children }) => {
  const ref = useRef(null)
  const report = useContext(ReportContext)
  function downloadCsv(csv) {
    var csvFile
    var downloadLink
    csvFile = new Blob([csv], { type: "text/csv" })
    downloadLink = document.createElement("a")
    downloadLink.download = report.start_date + '_' + report.end_date + '_' +title.toLowerCase().replace(/[^a-z0-9]/g, "-") + ".csv"
    downloadLink.href = window.URL.createObjectURL(csvFile)
    downloadLink.style.display = "none"
    document.body.appendChild(downloadLink)
    downloadLink.click()
  }
  function exportTableToCsv() {
    var csv = []
    var rows = ref.current.querySelectorAll("table tr")
    for (var i = 0; i < rows.length; i++) {
      var row = [], cols = rows[i].querySelectorAll("td, th")
      for (var j = 0; j < cols.length; j++)
        row.push(cols[j].innerText)
      csv.push(row.map((i) => `"${i}"`).join(","))
    }
    downloadCsv(csv.join("\n"))
  }
  return (
    <div>
      <div className="flex justify-between gap-4 mb-4">
        <h1 className="text-4xl">{title}</h1>
        <Button onClick={exportTableToCsv}>Export to CSV</Button>
      </div>
      <div ref={ref}>
        {children}
      </div>
    </div>
  )
}

export default function () {

  const { id } = useParams()
  const [categories, setCategories] = useState(defaultCategories)
  const [loading, setLoading] = useState(true)
  const [report, setReport] = useState({})

  useEffect(() => {
    fetch(`/api/reports/${id}.json`)
      .then(res => res.json())
      .then(setReport)
      .then(() => setLoading(false))
  }, [id])

  const totalPriceByFinanceCategory = (financeCategory = false) => {
    return filteredTransactions
      .filter(transaction => {
        if (financeCategory) return transaction.destinations.findIndex((d) => d.financeCategory == financeCategory) >= 0
        return transaction.destination_ids.length == 0
      })
      .reduce((acc, transaction) => acc + transaction.total_price / parseFloat(financeCategory ? transaction.destination_ids.length : 1), 0)
  }

  const totalCostByFinanceCategory = (financeCategory = false) => {
    return filteredTransactions
      .filter(transaction => { 
        if (financeCategory) return transaction.destinations.findIndex((d) => d.fincacneCategory == financeCategory) >= 0
        return transaction.destination_ids.length == 0
      })
      .reduce((acc, transaction) => acc + transaction.total_cost / parseFloat(financeCategory ? transaction.destination_ids.length : 1), 0)
  }

  const totalPriceByDestination = (destinationId = false) => {
    return filteredTransactions
      .filter(transaction => {
        if (destinationId) return transaction.destination_ids.indexOf(destinationId) >= 0
        return transaction.destination_ids.length == 0
      })
      .reduce((acc, transaction) => acc + transaction.total_price / parseFloat(destinationId ? transaction.destination_ids.length : 1), 0)
  }

  const totalCostByDestination = (destinationId = false) => {
    return filteredTransactions
      .filter(transaction => { 
        if (destinationId) return transaction.destination_ids.indexOf(destinationId) >= 0
        return transaction.destination_ids.length == 0
      })
      .reduce((acc, transaction) => acc + transaction.total_cost / parseFloat(destinationId ? transaction.destination_ids.length : 1), 0)
  }

  const totalPriceByRegion = (regionId = false) => {
    return filteredTransactions
      .filter(transaction => {
        if (regionId) return transaction.region_id == regionId
        return transaction.region_id == null
      })
      .reduce((acc, transaction) => acc + transaction.total_price, 0)
  }

  const totalCostByRegion = (regionId = false) => {
    return filteredTransactions
      .filter(transaction => { 
        if (regionId) return transaction.region_id == regionId
        return transaction.region_id == null
      })
      .reduce((acc, transaction) => acc + transaction.total_cost, 0)
  }

  const totalPriceByCategory = (category = false) => {
    return filteredTransactions
      .filter(transaction => {
        if (category) return transaction.category == category
        return transaction.category == null
      })
      .reduce((acc, transaction) => acc + transaction.total_price, 0)
  }

  const totalCostByCategory = (category = false) => {
    return filteredTransactions
      .filter(transaction => { 
        if (transaction.category == null && category == '') return true
        if (category) return transaction.category == category
      })
      .reduce((acc, transaction) => acc + transaction.total_cost, 0)
  }

  const updateCategories = (value, e) => {
    setCategories(categories.map(category => {
      if (category.value == value) {
        return { ...category, selected: e.target.checked }
      } else {
        return category
      }
    }))
  }

  const filteredTransactions = useMemo(
    () => {
      if (!report.transactions?.length) return
      const selectedCategories = categories.filter(category => category.selected).map(category => category.value == '' ? null : category.value)
      const transactions = report.transactions.map(transaction => {
        return {
          ...transaction,
          destinations: transaction.destination_ids.map(destinationId => report.destinations.find(destination => destination.record_id == destinationId)),
        }
      })
      if (selectedCategories.length == 0) return transactions
      return transactions.filter(transaction => {
        return selectedCategories.indexOf(transaction.category) != -1
      })
    },
    [report.transactions, categories]
  )

  const financeCategories = useMemo(
    () => {
      if (!report.destinations?.length) return []
      return report.destinations.map(destination => [destination.financeCategory, destination.tourcode]).sort((a, b) => a[0] > b[0] ? 1 : -1)
    },
    [report.destinations]
  )

  if (loading) return <Loading />

  return (
    <ReportContext.Provider value={report}>
      <div className="flex justify-between gap-4 mb-4 items-end">
        <div className='flex gap-4'>
          <h1 className="text-4xl">Categories</h1>
        </div>
      </div>
      <div className="mb-12">
        <div className="flex gap-2 flex-wrap mb-4">
          {categories.map(category =>
            <label key={category.value} className="flex gap-2 p-2 bg-gray-100 rounded">
              <input type="checkbox" onChange={(e) => updateCategories(category.value, e)} checked={category.selected ?? false} />
              {category.label == '- select -' ? 'Uncategorized' : category.label}
            </label>
          )}
        </div>
      </div>
      <div className="mb-12">
        <CsvTable title="Totals by Category">
          <Table className="mb-8">
            <Table.Head>
              <Table.Row>
                <Table.Th>Category</Table.Th>
                <Table.Th>Total Price</Table.Th>
                <Table.Th>Total Cost</Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {categories.filter((c) => c.selected).map(category => (
                <Table.Row key={category.value}>
                  <Table.Td>{category.label == '- select -' ? 'Uncategorized' : category.label}</Table.Td>
                  <Table.Td className="text-right">{numeral(totalPriceByCategory(category.value)).format('$0,0.00')}</Table.Td>
                  <Table.Td className="text-right">{numeral(totalCostByCategory(category.value)).format('$0,0.00')}</Table.Td>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </CsvTable>
      </div>
      <div className="mb-12">
        <CsvTable title="Totals by Region">
          <Table className="mb-8">
            <Table.Head>
              <Table.Row>
                <Table.Th>Region</Table.Th>
                <Table.Th>Total Price</Table.Th>
                <Table.Th>Total Cost</Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Td>No Region</Table.Td>
                <Table.Td className="text-right">{numeral(totalPriceByRegion()).format('$0,0.00')}</Table.Td>
                <Table.Td className="text-right">{numeral(totalCostByRegion()).format('$0,0.00')}</Table.Td>
              </Table.Row>
              {report.regions?.map(region => (
                <Table.Row key={region.record_id}>
                  <Table.Td>{region.name}</Table.Td>
                  <Table.Td className="text-right">{numeral(totalPriceByRegion(region.record_id)).format('$0,0.00')}</Table.Td>
                  <Table.Td className="text-right">{numeral(totalCostByRegion(region.record_id)).format('$0,0.00')}</Table.Td>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </CsvTable>
      </div>
      <div className="mb-12">
        <CsvTable title="Totals by Finance Category">
          <Table className="mb-8">
            <Table.Head>
              <Table.Row>
                <Table.Th>Finance Category</Table.Th>
                <Table.Th>Tourcode</Table.Th>
                <Table.Th>Total Price</Table.Th>
                <Table.Th>Total Cost</Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Td>No Category</Table.Td>
                <Table.Td>No Tourcode</Table.Td>
                <Table.Td className="text-right">{numeral(totalPriceByFinanceCategory()).format('$0,0.00')}</Table.Td>
                <Table.Td className="text-right">{numeral(totalCostByFinanceCategory()).format('$0,0.00')}</Table.Td>
              </Table.Row>
              {financeCategories.map(([category, tourcode]) => (
                <Table.Row key={tourcode}>
                  <Table.Td>{category}</Table.Td>
                  <Table.Td>{tourcode}</Table.Td>
                  <Table.Td className="text-right">{numeral(totalPriceByFinanceCategory(category)).format('$0,0.00')}</Table.Td>
                  <Table.Td className="text-right">{numeral(totalCostByFinanceCategory(category)).format('$0,0.00')}</Table.Td>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </CsvTable>
      </div>
      <div className="mb-12">
        <CsvTable title="Totals by Destination">
          <Table className="mb-8">
            <Table.Head>
              <Table.Row>
                <Table.Th>Destination</Table.Th>
                <Table.Th>Total Price</Table.Th>
                <Table.Th>Total Cost</Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Td>No Destination</Table.Td>
                <Table.Td className="text-right">{numeral(totalPriceByDestination()).format('$0,0.00')}</Table.Td>
                <Table.Td className="text-right">{numeral(totalCostByDestination()).format('$0,0.00')}</Table.Td>
              </Table.Row>
              {report.destinations?.map(destination => (
                <Table.Row key={destination.record_id}>
                  <Table.Td>{destination.name}</Table.Td>
                  <Table.Td className="text-right">{numeral(totalPriceByDestination(destination.record_id)).format('$0,0.00')}</Table.Td>
                  <Table.Td className="text-right">{numeral(totalCostByDestination(destination.record_id)).format('$0,0.00')}</Table.Td>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </CsvTable>
      </div>
      <CsvTable title="Transactions">
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>Date</Table.Th>
              <Table.Th>Category</Table.Th>
              <Table.Th>Products</Table.Th>
              <Table.Th width="120">Product count</Table.Th>
              <Table.Th width="120">Total Price</Table.Th>
              <Table.Th width="120">Total Cost</Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {filteredTransactions.map(transaction => (
              <Table.Row key={transaction.id}>
                <Table.Td>{transaction.id}</Table.Td>
                <Table.Td>{transaction.created_at}</Table.Td>
                <Table.Td>{transaction.category}</Table.Td>
                <Table.Td>
                  <ul className="list-disc pl-6">
                    {transaction.items_attributes && transaction.items_attributes.map((item) =>
                      <li key={item.id}>{item.product.magento_id} - {item.quantity} - {item.title}</li>
                    )}
                  </ul>
                </Table.Td>
                <Table.Td className="text-right">{transaction.product_count}</Table.Td>
                <Table.Td className="text-right">{numeral(transaction.total_price).format('$0,0.00')}</Table.Td>
                <Table.Td className="text-right">{numeral(transaction.total_cost).format('$0,0.00')}</Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </CsvTable>
    </ReportContext.Provider>
  )
}
