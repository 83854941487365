import React, { useState, useEffect } from 'react'
import Select2 from 'react-select'
import regionStore from '../stores/region'

export default function ({onChange, value}) {

  const [defaultRegion, setDefaultRegion] = useState(null);

  const [regions, fetchRegions] = regionStore(state => [state.collection, state.fetch])

  useEffect(() => {
    fetchRegions()
  }, [fetchRegions])

  useEffect(() => {
    if (!regions) return
    setDefaultRegion(regions.find((r) => r.value == value))
  }, [regions, value])

  return (
    <div className="mb-4">
      <label className="block font-bold">Region</label>
      <Select2
        options={regions}
        onChange={onChange}
        menuPlacement="top"
        value={defaultRegion}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        isClearable
      />
    </div>
  )
}
